<template>
  <section class="menu" id="menu">
    <nav class="menu-container ">
    <!-- LOGO -->
      <ul class="menu-container-logo">
        <li class="menu-container-logo-elements">
          <a href="#home">
            <img src="../assets/img/logotext.svg" alt="KATI Logo" class="menu-container-logo-elements-img">
          </a>
        </li>
      </ul>
      <!-- NAVIGATION -->
      <scrollactive ref="scrollactive" class="my-nav" :offset="90" :always-track="false">
        <ul class="menu-container-elements">
          <li class="menu-container-elements-nav">
            <a href="#home" class="scrollactive-item"> Home </a>
          </li>
          <li class="menu-container-elements-nav">
            <a href="#about" class="scrollactive-item"> About </a>
          </li>
          <li class="menu-container-elements-nav">
            <a href="#services" class="scrollactive-item"> Services </a>
          </li>
          <li class="menu-container-elements-nav"> 
            <a href="#contact" class="scrollactive-item"> Contact </a>
          </li>
        </ul>
      </scrollactive>

      <!-- LANGUAGE -->
      <ul>
        <li class="menu-container-elements-language" v-html="$t('language')" />
      </ul>
  
      <!-- BURGER -->
      <div class="menu-toggle">
        <div class="menu-toggle-decoration">
          <div class="menu-toggle-decoration-text"> Menu</div>
          <span id="menu-toggle-decoration-stroke-one"></span>
          <span id="menu-toggle-decoration-stroke-two"></span>
          <div class="menu-toggle-decoration-all" id="hamburger-menu">  
                  
            <svg  class="svg-logo" width="122px" height="22px" viewBox="0 0 122 22" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">  
              <g id="9th-correction" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="Double-green-JP-#1" transform="translate(-123.000000, -76.000000)" class="svg-logo-filler">
                  <g id="Black-text" transform="translate(123.000000, 76.000000)">
                    <polygon id="Fill-1" points="73.5 0 75.5846203 4.2945874 80.6544724 4.2945874 80.6544724 22 85.4444047 22 85.4444047 4.2945874 90.5 4.2945874 90.5 0"></polygon>
                    <polygon id="Fill-2" points="116.5 22 121.5 22 121.5 0 116.5 0"></polygon>
                    <polygon id="Fill-3" points="36.5 22 41.3206173 22 46.6421157 10.3271813 51.6538374 22 56.5 22 46.6796252 0"></polygon>
                    <polygon id="Fill-4" points="11.6250715 0 4.82080589 7.23442768 4.82080589 0 0.5 0 0.5 22 4.82080589 22 4.82080589 13.2761313 11.6269534 22 17.3660726 22 7.80892419 10.0669033 17.5 0"></polygon>
                  </g>
                </g>
              </g>
            </svg>

            <!--STROKE -->
            <span class="menu-toggle-decoration-scroll">
              <p class="menu-toggle-decoration-scroll-element"> Menu </p>
            </span>
            <div class="menu-toggle-decoration-stroke"></div>

            <scrollactive ref="scrollactive" class="my-nav" :offset="70" :always-track="false">
              <ul class="menu-toggle-decoration-wrap">
                <li><a href="#home" class="menu-toggle-decoration-wrap-element scrollactive-item">Home</a></li>
                <li><a href="#about" class="menu-toggle-decoration-wrap-element scrollactive-item">About</a></li>
                <li><a href="#services" class="menu-toggle-decoration-wrap-element scrollactive-item">Services</a></li>
                <li><a href="#contact" class="menu-toggle-decoration-wrap-element scrollactive-item">Contact</a></li>
              </ul>
            </scrollactive>
            <ul class="menu-toggle-decoration-language">
              <li class="menu-toggle-decoration-language-stroke"></li>
              <li class="menu-toggle-decoration-language-text" v-html="$t('language-burger')" />
            </ul>
          </div>
        </div>
      </div>
    </nav>
  </section>
</template>

<script>

window.onscroll = function() {scrollFunction()};

function scrollFunction() {
  if (window.matchMedia("(min-width: 951px)").matches && document.body.scrollTop > 100 || window.matchMedia("(min-width: 951px)").matches && document.documentElement.scrollTop > 100) {
    document.getElementById("menu").style.top = "-20px";
  } else {
    document.getElementById("menu").style.top = "0px";
  }
}

document.addEventListener('click', function (event) {
	if (event.target.matches('.menu-toggle-decoration-wrap-element') || event.target.matches('.menu-toggle-decoration-text')){
    document.getElementById('menu-toggle-decoration-stroke-one').classList.toggle('change-stroke-one');
    document.getElementById('menu-toggle-decoration-stroke-two').classList.toggle('change-stroke-two');
    document.querySelector('.menu-toggle-decoration-text').classList.toggle('active-menu');
    if (document.querySelector('.menu-toggle-decoration-text').classList.contains('active-menu')) {
      document.getElementById("hamburger-menu").style.transform = "none";
    } else {
      document.getElementById("hamburger-menu").style.transform = "translate(0%, -100%)";
    }
  }
}, false);

export default {
  name: "Menu",
  methods: {
  },
  props: {

  }
}
</script>


<style lang="scss" scoped>


/********* 

SETTING MENU

**********/
#menu{
  transition: 0.4s;
}

.menu{
  background-color: #114133;
  width: 100vw;
  height: 110px;
  padding-top: 55px;
  position:fixed; 
  left:0;           
  top:0;            
  width:100vw;     
  z-index:12;
}

ul { display: inline-block;}

ul > li { display: inline-block;}

ul > li > a { 
  color: #ffffff;
}

.menu-container{
  width: 82vw;
  margin: auto;
  z-index:12;   
}

.menu-container-logo{
  position: absolute;
  top: 50px;
  left: 10.4vw;
}

/********* 

NAVIGATION MENU

**********/

.menu-container-elements{
  width: 33vw;
  margin-left: 30.7vw;
  margin-right: auto;
  display: block;
}

.menu-container-elements-nav{
  margin-right: 4vw;

  &:nth-of-type(4) {
    margin-right: 0;
  }
  a {
    font-family: 'Montserrat', Arial, sans-serif;
    font-size: 16px;
    font-weight: 400;
    position: relative;
    width: 100%;
    display: block;
    &:after {
      content: "";
      position: absolute;
      top: 100%;
      margin-top:4px;
      left: 0px;
      width: 20px;
      height: 5px;
      -webkit-transition: all 0.2s ease-in-out;
      transition: all 0.2s ease-in-out;
      -webkit-transition-duration: 0.45s;
      transition-duration: 0.45s;
      transform: scaleX(0);
      transform-origin: left;
      opacity: 0;
      height: 2px;
      background: #ffffff;
    }

    &.is-active {
      &:after {
        transform: scaleX(1);
        opacity: 1;
      }
    }
  }

  &:hover {
    a {
      &:after {
        transform: scaleX(1);
        opacity: 1;
      }
    }
  }
}

.menu-container-elements-language{
  font-family: 'Montserrat', Arial, sans-serif; 
  font-size: 18px;
  font-weight: 700;
  color: #ffffff;
  position: absolute;
  right: 10vw;
  top: 53px;

  // This is the bar before EN
  &:before{
    content: "";
    width: 6px;
    height: 2px;
    display: block;
    position: absolute;
    top: 11px;
    left: -17px;
    background-color: #ffffff;
    transition: width 0.2s ease;
  }
}

.menu-container-elements-language-white > a{ 
  color: white;
  padding: 10px;
}

.menu-container-elements-language:hover{
  &:before{
    width: 60px;
    z-index:-1;
  }
}

//ANIMATION

@keyframes stroke{
  from {opacity: 0;
  transform: scaleX(0);
  transform-origin: top left;
  }
  to {opacity: 1;
  transform: scaleX(20px);
  transform-origin: top left;
  }
}

@-webkit-keyframes stroke{
  from {opacity: 0;
  transform: scaleX(0);
  transform-origin: top left;
  }
  to {opacity: 1;
  transform: scaleX(20px);
  transform-origin: top left;
  }
}

//menu burger
.menu-toggle{
  display: none;
  position: relative;
  cursor: pointer;
}

span:nth-last-child(2){
  opacity:0;
}


/************************ 
*************************

RESPONSIVE

*************************
************************/

/********* 
********* 
1088
********* 
**********/

@media screen and (max-width: 1088px){
.menu-container-elements{
    width: 54vw;
    margin-left: 30.7vw;
    margin-right: auto;
    display: block;
}
}

/********* 
********* 
950
********* 
**********/
@media screen and (max-width: 950px){

.menu-container{
  width: 82vw;
  margin: auto;
  z-index:12;   
}

/*********

CROSS DECORATION BURGER

*********/

a{
  text-decoration: none;
  color: #232323;
  
  transition: color 0.3s ease;
}

.menu-toggle{
  display: block;
  position: relative;
  width: 100vw;
  top: 0px;
  left: 0px;
  z-index: 11;
  
  -webkit-user-select: none;
  user-select: none;
}

.menu-toggle-decoration{
  position: absolute;
  right: 21vw;
  top: -35px;
  z-index: 10;
}

.menu-toggle-decoration-text{
  position: absolute;
  left: 0px;
  top: -28px;
  padding: 20px 15px;
  color: #ffffff;
  font-family: 'Montserrat', Arial, sans-serif;
  font-size: 13px;
  z-index: 3; 
  display: inline-block;
  transition: color 0.4s ease;
  
  &.active-menu{
    color: #114133;
    z-index: 3;
  }
}

.menu-toggle .menu-toggle-decoration span{
  display: block;
  width: 5px;
  height: 1px;
  position: relative;
  
  background: #ffffff;
  border-radius: 3px;
  
  z-index: 1;
  
  transform-origin: 4px 0px;
  
  transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0), background 0.5s cubic-bezier(0.77,0.2,0.05,1.0), opacity 0.55s ease;

  &.change-stroke-one{
    opacity: 1;
    transform: rotate(45deg) translate(1px, 1px);
    background: #114133;
  }

  &.change-stroke-two{
    opacity: 1;
    transform: rotate(-45deg) translate(0.3px, 1.3px);
    background: #114133;
  }
}

.menu-toggle .menu-toggle-decoration span:first-child{
  transform-origin: 0% 0%;
}

.menu-toggle .menu-toggle-decoration span:nth-last-child(2){
  transform-origin: 0% 100%;
}
/********* 

MENU BURGER

**********/

ul > li { display: block;}

.svg-logo{
  width: 95px;
  height: auto;
  position: absolute;
  top: 25px;
  left: 63px;
}

.svg-logo-filler{
  fill: #114133;
}

.menu-toggle-decoration-all{
  position: fixed;
  top:0;
  left: 0;
  width:100vw;
  background-color: #ffffff;
  height: 416px;
  z-index: 0;
  box-shadow: -1px 2px 34px 10px rgba(0, 0, 0, 0.09);
  -webkit-font-smoothing: antialiased;
  /* to stop flickering of text in safari */
  transform-origin: 0% 0%;
  transform: translate(0%, -100%);
  transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
}

.menu-toggle-decoration-wrap{
  margin-left: 9.5rem;
  margin-top: 130px;
}

.menu-toggle-decoration-wrap-element{
  color: #291f1f;
  opacity: 0.3;
  font-family: 'noe-display-bold', Georgia, 'Times New Roman', Times, serif;
  font-size: 34px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 1.89px;
  margin-bottom: 2px;
}

.menu-toggle-decoration-language{
  margin-top: 40px;
  margin-left: 9.5rem;
}

.menu-toggle-decoration-language-text{
  display: inline-block;
}
/********* 

STROKE

**********/

.menu-toggle-decoration-stroke{
  opacity: 0.1;
  border: solid 0.03125rem #000000;
  background-color: #000000;
  margin-left: 8.6vw;
  position:absolute;
  bottom: 0;
  height: 12.5rem;
}

.menu-toggle-decoration-language-stroke{
    border: solid 1px #000000;
    margin-left: 5px;
    bottom: 0;
    width: 9px;
    margin-right: 12px;
    margin-bottom: 5px;
    display: inline-block;
}

/********* 

SCROLL

**********/

.menu-toggle-decoration-scroll-element{
  transform: rotate(90deg);
  color: #291f1f;
  font-family: 'Montserrat', Arial, sans-serif;
  font-size: 13px;
  position: relative;
  top: 129px;
  opacity: 0.4;
  margin-left: 8.7vw;
}


/********* 

SETTING

**********/

.menu{
  width: 100vw;
  height: 70px;
  padding-top: 50px;
  left:0;           
  top:0;            
  width:100vw;     
  z-index:11;       
}


// ANIMATION
.is-active{
  color: #BF0F48;
  opacity: 1;
  transition: transform 1s cubic-bezier(0.77,0.2,0.05,1.0), background 2s cubic-bezier(0.77,0.2,0.05,1.0), opacity 0.5s ease;

}

.menu-toggle-decoration-wrap-element:hover{
  color: #BF0F48;
  opacity: 1;
  transition: transform 1s cubic-bezier(0.77,0.2,0.05,1.0), background 2s cubic-bezier(0.77,0.2,0.05,1.0), opacity 0.55s ease;
}

.menu-container-logo{
  top: 1.5625rem;
  left: 7.5vw;
}

.menu-container-logo-elements-img{
  width: 95px;
} 

.menu-container-elements{
  display: none;
}

.menu-container-elements-language{
  display: none;
  }
}

/********* 
********* 
610
********* 
**********/

@media screen and (max-width: 610px){

.menu-toggle-decoration{
  position: absolute;
  right: 120px;
  top: -35px;
}

.menu-toggle-decoration-wrap{
  margin-left: 5.5rem;
  margin-top: 130px;
}

.svg-logo{
  width: 95px;
  height: auto;
  position: absolute;
  top: 25px;
  left: 28px;
}
/********* 

STROKE

**********/

.menu-toggle-decoration-stroke{
  margin-left: 7.7vw;
}


/********* 

SCROLL

**********/

.menu-toggle-decoration-scroll-element{
  margin-left: 8vw;
}

.menu-toggle-decoration-language{
  margin-left: 5.5rem;
}

}

/********* 
********* 
320
********* 
**********/

@media screen and (max-width: 320px){
.menu-toggle-decoration{
    right: 35vw;
}
}


//IE 11

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none){
  .svg-logo{top: -170px;}
}

@media screen and (-ms-high-contrast: active) and (max-width: 610px), (-ms-high-contrast: none) and (max-width: 610px){
  .svg-logo{
  top: -170px;
  left:25px;}
}

@media screen and (-ms-high-contrast: active) and (max-width: 350px), (-ms-high-contrast: none) and (max-width: 350px){
   .menu-toggle-decoration-wrap{
     margin-left: 3rem; 
   } 
   .menu-toggle-decoration-language{
     margin-left: 3rem;
   }
}


</style>
