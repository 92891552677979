<template onload="animateHTML().init();">
  <div id="app" :class='$i18n.locale'>
    <!-- Put the components here to display on index -->
    <router-view/>
  </div>
</template>

<script>
export default {
  name: "app",
};
</script>

<style>

/** Reset */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

ul {
  list-style: none;
}

/** Selection */
/* WebKit/Blink Browsers */
::selection {
  background: #E5024C;
  color: #ffffff; 
}

/* Gecko Browsers */
::-moz-selection {
  background: #E5024C;
  color: #ffffff;  
}

/** Link */

a{
  text-decoration: none;
  color: #ffffff;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}

.menu-bg-content-elements-language-burger-link{
  color: #291f1f;
  font-family: 'Montserrat', Arial, sans-serif;
  font-weight: bold;
}

body,
html {
  width: 100%;
  height: 100%;
}

.introduction{
  height: 100%;
}

.jp{
  font-family: hiragino-normal;
}
/** Style global */
@import './assets/style/color.scss';
@import './assets/style/font.scss';
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

</style>
