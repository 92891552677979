import loadAssets from 'load-asset';

class LoadAssets {
  constructor(elements) {
    this.elements = elements;
    this.requests = [];
    this.progressEvents = [];
    this.completeEvents = [];
    this.totalProgress = 0;
    this.assetsReady = false;

    this.init();
  }

  init() {
    const els = this.elements.map(el => el.getAttribute('src'));

    loadAssets.any(els, (e) => {
      if (e.progress === 1) {
        this.totalProgress = 100;
        this.progressEventsCall();
        this.completeEventsCall();
      } else {
        this.totalProgress = Math.floor(e.progress * 100);
        this.progressEventsCall();
      }
    });
  }

  progressEventsCall() {
    this.progressEvents.forEach(({ callback }) => {
      callback(this.totalProgress);
    });
  }

  completeEventsCall() {
    this.completeEvents.forEach(({ callback }) => {
      callback();
    });
  }

  on(event, callback) {
    switch (event) {
      case 'progress':
        this.progressEvents.push({ callback });
        break;

      case 'complete':
        this.completeEvents.push({ callback });
        break;

      default:
        break;
    }
  }
}
export default LoadAssets;
