import Vue from 'vue'
import Router from 'vue-router'
import Default from './components/Default'
import NotFound from "./components/404";
import i18n from '@/i18n'

Vue.use(Router)
Vue.use(i18n)


export default new Router({
  mode: 'history',
  hashbang: false,
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      redirect: `/${i18n.locale}`,
    },
    {
      path: '/:lang',
      component: {
        render (c) { return c('router-view') }
      },
      children: [
        {
          path: '/jp',
          name: 'jp',
          component: Default,
          meta: {
            metaTags: [
              {
                name: 'description',
                content: 'KATIはビジネスに価値を付加します。戦略・戦術の立案から課題発見・改善などの運用までデジタル領域に関わる事業を一貫してコンサルティングいたします。'
              },
              {
                property: 'og:description',
                content: 'KATIはビジネスに価値を付加します。戦略・戦術の立案から課題発見・改善などの運用までデジタル領域に関わる事業を一貫してコンサルティングいたします。'
              }
            ]
          }
        },
        {
          path: '/en',
          name: 'en',
          component: Default,
          meta: {
            metaTags: [
              {
                name: 'description',
                content: 'We add value in your business. We consult your business with planning strategy and solving problems in digital field.'
              },
              {
                property: 'og:description',
                content: 'We add value in your business. We consult your business with planning strategy and solving problems in digital field.'
              }
            ]
          }
        },
        {
          path: '/jp/',
          redirect: `/${i18n.locale}`,
        },
        {
          path: '/en/',
          redirect: '/en',
        },
        { 
          path: '/*', 
          component: NotFound
        }
      ]
    }
  ]
})