<template>
  <div id="english">
    <!-- Put the components here to display on index -->
    <Loader/>
    <Menu/>
    <Home/>
    <About/>
    <Services/>
    <Contact/>
    <Footer/>
  </div>
</template>

<script>
import Menu from "./Menu.vue";
import Home from "./Home.vue";
import About from "./About.vue";
import Services from "./Services.vue";
import Contact from "./Contact.vue";
import Footer from "./Footer.vue";
import Loader from "./Loader.vue";


import LoadAssets from '@/loader';

export default {
  name: "english",
  components: {
    Menu,
    Home,
    About,
    Services,
    Contact,
    Footer,
    Loader,
  },
  mounted() {
    const images = document.querySelectorAll('img');
    const loadAssets = new LoadAssets(Array.from(images));
    const loaderStrokes = document.querySelectorAll('.loader-stroke');
    const loader = document.querySelector('.loader');
    const home = document.querySelector('.introduction');
    loadAssets.on('progress', () => {
      loaderStrokes.forEach((element) => {
        element.style.transform = 'scaleX(' + loadAssets.totalProgress / 100 + ')';
      })
    });
    loadAssets.on('complete', () => {
      loader.classList.add('hide');
      home.classList.add('show');
    });
  }
};
</script>
