<template>
  <div class="notfound">
    <!-- Put the components here to display on index -->
    <MenuNotFound/>
    <div class="notfound-container">
      <div class="notfound-container-bodytext">
        <!-- TITLE H1 -->
        <h1 class="notfound-container-bodytext-title animated-fade-in-up fade-in-up animated-title">
          <span class="notfound-container-bodytext-title-gradient">Sorry</span>, this page doesn’t exist
        </h1>
        <!-- BUTTON -->
        <div class="notfound-bodytext-button animated-fade-in-up fade-in-up animated-button">
          <a href="/jp" class="notfound-bodytext-button-text"> Back to home </a>
        </div>
      </div>
      <!-- BACKGROUND STROKE TEXT -->
      <span class="notfound-container-element fade-in-up animated-fade-in-up animated-background-text">
        <p class="notfound-container-element-text">404</p>
      </span>

      <!-- FLEXING THE BACKGROUND -->
      <span class="notfound-container-flex fade-in-up animated-fade-in-up animated-background-text">
        <p class="notfoundn-container-flex-text">4<br/>04 </p>
      </span>

      <!-- BACKUP IE11 -->
      <!-- NORMAL DESKTOP -->
      <span class="notfound-container-img-IE">
        <img src="../assets/img/IE/IE-desktop-image-404@1440.png" alt="404 Page not found">
      </span>
       
       <!-- FLEX -->
      <span class="notfound-container-img-IE-flex">
        <img src="../assets/img/IE/IE-mobile-image-404@610.png" alt="404 Page not found">
      </span>
             
      <!-- SCROLL -->
      <span class="notfound-container-stroke animated-fade-in-up fade-in-up animated-button">
        <span class="notfound-container-stroke-element"></span>
      </span>
    </div>
  </div>
</template>

<script>
import MenuNotFound from "./Menu404.vue";

export default {
  name: "MenuNotFoundPage",
  components: {
    MenuNotFound
  }
};
</script>

<style lang="scss" scoped>

.notfound{
  width: 100vw;
  height: 100vh;
  
  background-color: #114133;
  position: relative;
  overflow-x: hidden;
  overflow-y: hidden;
}

.fade-in-up {
  opacity: 0;
  animation-name: fadeInUp;
  -webkit-animation-name: fadeInUp;
}

@keyframes fadeInUp {
  from {
    transform: translate3d(0,40px,0)
  }
  to {
    transform: translate3d(0,0,0);
    opacity: 1
  }
}

.animated-fade-in-up {
    opacity: 0
}

.animated-title{
    animation-duration: 1s;
    animation-fill-mode: both;
    -webkit-animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-delay: 0.4s;
}

.animated-background-text{
    animation-duration: 1s;
    animation-fill-mode: both;
    -webkit-animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-delay: 0;
}

.animated-button{
    animation-duration: 1s;
    animation-fill-mode: both;
    -webkit-animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-delay: 0.7s;
}

.notfound-container{
  width: 82vw;
  margin: auto;
}


/********* 

TITLE

**********/

.notfound-container-bodytext{
  margin-left: 26vw;
  margin-top: 41vh;
  position: relative;
  z-index: 6;
}


.notfound-container-bodytext-title{
  width: 497px;
  height: auto;
  font-family: 'noe-display-medium', Georgia, 'Times New Roman', Times, serif;
  font-weight: 400;
  font-size: 4.4625em;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.14;
  letter-spacing: 0.0065rem;
  color: #ffffff;
  z-index : 1;
  position: relative;
}

.notfound-container-bodytext-title-gradient{
  background-repeat:no-repeat;
  background-image: linear-gradient(90deg, rgba(236,0,76,1) 0%, rgba(19,65,52,1) 100%);
  background-size: 100% 2rem;
  background-position: 0.775rem 56%;
  z-index: -2;
}


/********* 

BACKGROUND TEXT

**********/


.notfound-container-element{
  width: 83.5vw;
  height: 12.5rem;
  -webkit-text-stroke: 4px rgba(255,255,255, 0.05);
  font-family: 'Montserrat', Arial, sans-serif;
  font-size: 350px;
  font-weight: bold;
  line-height: 0.91;
  letter-spacing: 0.25rem;
  color: #114133;
  text-transform: uppercase;
  position: absolute;
  z-index: 0;


  &:first-of-type {
    left: 29vw;
    top: 23vh;
  }
}

.notfound-element-flex{ display:none;}
.notfound-container-flex{display:none;}

//IE 11
.notfound-container-img-IE{display: none}
.notfound-container-img-IE-flex{display: none}

/********* 

BUTTON BACK TO HOME

**********/

.notfound-bodytext-button{
  margin-left: 25px;
  margin-top: 40px;
}
.notfound-bodytext-button-text{
  font-family: 'Montserrat', Arial, sans-serif;
  color: #ffffff;
  font-size: 1rem;
  display: inline-block;
  position: relative;
  z-index: 4;

  &:before{
  content: "";
  width: 6px;
  height: 2px;
  display: block;
  position: absolute;
  top: 10px;
  left: -17px;
  background-color: #ffffff;
  transition: width 0.2s ease;
  }

  &:hover{
  &:before{
    width: 145px;
    z-index:-1;
  }
}
}

/********* 

STROKE

**********/

.notfound-container-stroke-element{
  border-right: 0.0625rem solid #ffffff;
  height: 36vh;
  position: absolute;
  bottom:0;
  right: 88.5vw;

  &:before{
  content: "Page not found";
  color: #ffffff;
  font-family: 'Montserrat', Arial, sans-serif; 
  font-size: 1rem;
  display: inline-block;
  transform: rotate(90deg);
  position: relative;
  top: -17vh;
  left: 80px;
  width: 163px;
  }
}

/********* 
********* 
1120
********* 
**********/

@media screen and (max-width: 1120px){

//this is for placing the container of text
.notfound-container-bodytext{
    margin-left: 22vw;
}

//placing 404
.notfound-container-element{
  &:first-of-type {
    left: 22vw;
  }
}

}

//only for the title H1
@media screen and (max-width: 765px){

.notfound-container-bodytext{ margin-left: 16vw;}  

//H1 font size changing 
.notfound-container-bodytext-title{
  width: 75vw;
  font-size: 60px;
}

.notfound-container-bodytext-title-gradient{
  background-repeat:no-repeat;
  background-size: 100% 1.5rem;
  background-position: 0.775rem 56%;
  z-index: -2;
}

//Hide the first 404
.notfound-container-element-text{
  display: none;
}

//Show another 404 in two line
.notfound-container-flex{
  display: block;
  z-index : 0;
}
.notfoundn-container-flex-text{
  display: block;
  width: 83.5vw;
  height: 12.5rem;
  -webkit-text-stroke: 4px rgba(255,255,255, 0.05);
  font-family: 'Montserrat', Arial, sans-serif;
  font-size: 340px;
  font-weight: bold;
  line-height: 0.78;
  letter-spacing: 0.25rem;
  color: #114133;
  text-transform: uppercase;
  position: absolute;
  z-index: 0;


  &:first-of-type {
    left: 29vw;
    top: 23vh;
  }
}
}


/********* 
********* 
650
********* 
**********/

@media screen and (max-width: 650px){
  
.notfound-container-bodytext{
  margin-left: 15vw;
  margin-top: 41vh;
}

.notfound-container-bodytext-title{
  width: 305px;
  height: auto;
  font-weight: 400;
  font-size: 2.8125rem; 
  line-height: 1.04;
  letter-spacing: 0.0065rem;
  margin-top: 40.5vh;
  }

.notfound-container-bodytext-title-gradient{
  background-size: 100% 1.3rem;
  background-position: 0.5rem 55%;
}

//fontsize of new 404
.notfoundn-container-element-flex-text{
  font-size: 250px;
}
}

/********* 
********* 
400
********* 
**********/

@media screen and (max-width: 400px){

.notfound-container-bodytext{
  margin-left: 13vw;
  margin-top: -9vw;
}

.notfound-container-bodytext-title{
  width: 77vw;
}
}


//IE 11
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none){
  .notfound-container-bodytext-title-gradient{
  background-position: 0.775rem 68%;
}
  .notfound-container-stroke-element{
    bottom: -40vh;
    right: 0px;
  }

  .notfound-container-element{display: none;}
  .notfound-container-flex{display: none;}
  .notfound-container-img-IE{
    display: block;
    position: absolute;
    left: 29vw;
    top: 30vh;
  }
}

@media screen and (-ms-high-contrast: active) and (max-width: 1440px), (-ms-high-contrast: none) and (max-width: 1440px){
  .notfound-container-stroke-element{
    bottom: -40vh;
    right: 0px;
  }
   
  .notfound-container-element{display: none;}
  .notfound-container-img-IE{
    display: block;
    position: absolute;
    left: 29vw;
    top: 30vh;
  }
}

@media screen and (-ms-high-contrast: active) and (max-width: 1120px), (-ms-high-contrast: none) and (max-width: 1120px){
  .notfound-container-img-IE{
    left: 22vw;
  }
}
@media screen and (-ms-high-contrast: active) and (max-width: 765px), (-ms-high-contrast: none) and (max-width: 765px){
  .notfound-container-bodytext-title-gradient{
  background-position: 0.775rem 68%;
  }
  .notfound-container-flex{display: none;}
  .notfound-container-img-IE{
    left: 15vw;
  }
}

@media screen and (-ms-high-contrast: active) and (max-width: 650px), (-ms-high-contrast: none) and (max-width: 650px){
  .notfound-container-bodytext-title-gradient{
  background-position: 0.775rem 63%;
  }

  .notfound-container-stroke-element{
    bottom: -46vh;
    right: 0px;
  }

  .notfound-container-img-IE{display:none;}
  .notfound-container-img-IE-flex{
    display: block;
    position: absolute;
    left: 29vw;
    top: 32vh;
  }
}



</style>


