import Vue from 'vue'
import App from './App.vue'
import VueFlexLayout from 'vue-flex-layout'
import AOS from 'aos'
import 'aos/dist/aos.css'

// router
import router from './router.js'

// language
import VueI18n from 'vue-i18n'
import i18n from './i18n'
import VueScrollactive from 'vue-scrollactive'

Vue.use(AOS);
Vue.use(VueScrollactive);
Vue.use(VueI18n);
Vue.use(VueFlexLayout);
Vue.config.productionTip = false;

//INIT
AOS.init({
  duration: 800,
  easing: 'ease-in-out',
  once: true,
  mirror: false
})

// use beforeEach route guard to set the language
router.beforeEach((to, from, next) => {
  let referenceNode = document.head.querySelector('title + link');

  // use the language from the routing param or default language
  let language = to.path.substr(1);

  // set the current language for i18n.
  i18n.locale = language

  // Find the closest route with a title
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title)

  // Find the nearest route element with meta tags
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags)

  // If a route with a title was found, set the document title to that value
  if (nearestWithTitle) document.title = nearestWithTitle.meta.title

  // Remove any stale meta tags from the document using the key attribute we set below
  Array.from(document.querySelectorAll('.data-vue-router-controlled')).map(el => el.parentNode.removeChild(el))

  // Skip rendering meta tags if there are none
  if(!nearestWithMeta) return next()

  // Turn the meta tag definitions into actual elements in the head
  nearestWithMeta.meta.metaTags.map(tagDef => {
    const tag = document.createElement('meta')

    Object.keys(tagDef).forEach(key => {
      tag.setAttribute(key, tagDef[key])
    })

    // We use this to track which meta tags we create, so we don't interfere with other ones
    tag.setAttribute('data-vue-router-controlled', '')

    return tag
  })
  // Add the meta tags to the document head
  .forEach(tag => document.head.insertBefore(tag, referenceNode))

  next()
})

// Declaration of plugins there
const root = new Vue({
    created () {
      AOS.init()
    },
    render: h => h(App),
    router,
    i18n,
    components: {
        App,
        VueScrollactive,
    }
}).$mount('#app')

document.addEventListener('DOMContentLoaded', function () {
  root.$mount('#app')
})

window.snapSaveState = () => {
  document.querySelector('#app').setAttribute('data-server-rendered', 'true')
};


//Animation
var animateHTML = function() {
  var elems;
  var imgReveal;
  var windowHeight;
  function init() {
    elems = document.querySelectorAll('.hidden');
    imgReveal = document.querySelectorAll('.hidden-img');
    windowHeight = window.innerHeight;
    addEventHandlers();
    checkPosition();
  }
  function addEventHandlers() {
    window.addEventListener('scroll', checkPosition);
    window.addEventListener('resize', init);
  }
  function checkPosition() {
    for (var i = 0; i < elems.length; i++) {
      var positionFromTop = elems[i].getBoundingClientRect().top;
      if (positionFromTop - windowHeight <= 0) {
        elems[i].classList.remove('hidden');
        elems[i].classList.add('show-on-scroll-green');
      }

      var positionFromTop2 = imgReveal[i].getBoundingClientRect().top;
      if (positionFromTop2 - windowHeight <= 0) {
        imgReveal[i].classList.remove('hidden-img');
        imgReveal[i].classList.add('show-on-scroll-img');
      }
    }
  }
  
  return {
    init: init
  };
};
animateHTML().init();




